import React, { ReactElement, useEffect, useState } from "react";
import { navigate } from "gatsby";

import NavBar from "../../components/NavBar";
import iconCongrats from "../../assets/im-illustration-congrats.svg";

import {
  ContentContainer,
  Email,
  PageContainer,
  PageDescription,
  PageTitle,
  Image,
  Footer,
} from ".";
import { CustomizedDialogs } from "../../components/Footer/Modal";

function PasswordRecoverySuccessPage(): ReactElement {
  const [data, setData] = useState<{
    email: string;
    firstName: string | null;
  }>();

  useEffect(() => {
    if (URL && location) {
      const params = new URL(location.href).searchParams;
      const email = params.get("email");
      const firstName = params.get("firstName");

      if (!email) {
        navigate("https:stromee.de");
      } else {
        setData({
          firstName,
          email,
        });
      }
    }
  }, []);

  return (
    <>
      <NavBar onlyLogo />
      <PageContainer>
        <div className="container">
          <ContentContainer>
            {data?.firstName ? (
              <PageTitle>Willkommen bei stromee, {data.firstName}!</PageTitle>
            ) : (
              <PageTitle>Willkommen bei stromee, Fan!</PageTitle>
            )}
            <PageDescription>
              Dein neues Passwort für <Email>{data?.email}</Email> wurde
              erfolgreich gespeichert!
            </PageDescription>
            <PageDescription>
              Um dein Passwort nutzen zu können, öffne bitte deine stromee App.
            </PageDescription>
            <Image src={iconCongrats} />
          </ContentContainer>
          <Footer>
            <CustomizedDialogs />
          </Footer>
        </div>
      </PageContainer>
    </>
  );
}

export default PasswordRecoverySuccessPage;
